<template>
  <TabView @tab-change="generateKey">
    <TabPanel :header="$t('tabPanelSetup')">
      <div class="setup-tab-ct">
        <div class="label-input-group">
          <span class="new-ui-label">{{ $t('defaultLanguageLabel') }}:</span>
          <language-selector
            :value="config.defaultLang"
            @input="setDefaultLang"
            :disabled="!isAvailable"
          />
        </div>

        <div class="label-input-group">
          <span class="new-ui-label">
            {{ $t('additionalLanguagesLabel') }}:
          </span>
          <multi-language-selector
            :key="config.defaultLang"
            :value="additionalLanguages"
            @input="setLanguages"
            :disabled="!isAvailable"
            :default-language-code="config.defaultLang"
          />
        </div>
        <national-exclusion-check-settings
          class="label-input-group"
          v-if="
            $store.getters.isFeatureEnabled('nationalExclusionCheckBetsson') ||
            $store.getters.isFeatureEnabled('nationalExclusionCheckOasis')
          "
          v-model="config.exclusion"
          :disabled="readOnly"
        />
      </div>
    </TabPanel>
    <TabPanel :header="$t('tabPanelContent')">
      <!-- Content Section -->
      <div class="content-section-ct" :key="panelKeyState">
        <div class="content-section-wrapper">
          <div class="label-input-group">
            <span class="new-ui-label">
              {{ $t('inboxToolboxLargeTypeLabel') }}:
            </span>
            <Dropdown
              style="width: 100%"
              :options="inboxTypeOption"
              option-label="label"
              option-value="value"
              :disabled="!isAvailable"
              v-model="config.inbox_type"
              @change="updateInboxType($event)"
            />
          </div>
          <TabView @tab-change="setPreviewLanguage" :key="getKey">
            <TabPanel
              style="padding-bottom: 0"
              v-for="(lang, index) in sortedLanguages"
              :header="getLanguage(lang)"
              :tab-index="index"
              :key="lang"
            >
              <div class="language-content">
                <div class="label-input-group">
                  <span class="new-ui-label">
                    {{ $t('messageTitleLabel') }}
                  </span>
                  <redactor
                    id="push_title"
                    v-model="config.messages[lang].push_title"
                    :plugins="redactorPlugins"
                    :buttons="redactorButtons"
                    :custom-config="redactorConfig"
                    :use-default-plugins="false"
                    :use-default-buttons="false"
                    :disabled="!isAvailable"
                  />
                </div>

                <div class="label-input-group">
                  <span class="new-ui-label">{{ $t('messageTextLabel') }}</span>
                  <redactor
                    id="push_text"
                    v-model="config.messages[lang].push_text"
                    :plugins="redactorPlugins"
                    :buttons="redactorButtons"
                    :custom-config="redactorConfig"
                    :use-default-plugins="false"
                    :use-default-buttons="false"
                    :disabled="!isAvailable"
                  />
                </div>
              </div>
            </TabPanel>
          </TabView>

          <div
            class="color-picker"
            v-if="config.inbox_type === 'card'"
            ref="parent"
          >
            <div class="label-input-group">
              <span class="new-ui-label">
                {{ $t('inboxToolboxLargeTitleBackgroundColour') }}:
              </span>
              <div class="color-picker-ct">
                <InputText
                  v-model="config.inbox_style.title_bg"
                  :disabled="!isAvailable"
                />
                <el-color-picker
                  v-model="config.inbox_style.title_bg"
                  :disabled="!isAvailable"
                />
              </div>
            </div>

            <div class="label-input-group">
              <span class="new-ui-label">
                {{ $t('inboxToolboxLargeTitleBackgroundColour') }}:
              </span>
              <div class="color-picker-ct">
                <InputText
                  v-model="config.inbox_style.title_bg"
                  :disabled="!isAvailable"
                />
                <el-color-picker
                  v-model="config.inbox_style.bg"
                  :disabled="!isAvailable"
                />
              </div>
            </div>
          </div>

          <div v-if="voucherCodesEnabled">
            <div class="label-input-group">
              <span class="new-ui-label">{{ $t('voucherCodeTypeLabel') }}</span>
              <select-barcode-type
                :disabled="!isAvailable"
                v-model="config.voucher_code_enabled"
              />
            </div>
            <div
              v-if="(config.voucher_code_enabled || 0) === 1"
              class="label-input-group"
            >
              <span class="new-ui-label">
                {{ $t('voucherCodePositionLabel') }}
              </span>
              <select-barcode-position
                :disabled="!isAvailable"
                v-model="config.voucher_code_position"
              />
            </div>
          </div>

          <div class="label-input-group">
            <span class="new-ui-label">{{ $t('defaultActionLabel') }}</span>
            <Dropdown
              style="width: 100%"
              :options="defaultActionOptions"
              v-model="config.push_action.action"
              :disabled="!isAvailable"
              option-label="label"
              option-value="value"
              @change="setDefaultAction"
            />
          </div>

          <div v-if="config.push_action.action === 'url'" class="form-group">
            <div class="label-input-group">
              <span class="new-ui-label">
                {{ $t('inboxDefaultActionOpenUrlLabel') }}
              </span>
              <InputText
                type="text"
                v-model="config.push_action.url"
                :disabled="!isAvailable"
              />
            </div>
            <div class="label-switch-group">
              <span class="new-ui-label">
                {{ $t('inboxPushActionOpenInNewTab') }}
              </span>
              <InputSwitch
                :disabled="!isAvailable"
                v-model="config.push_action.url_blank"
              />
            </div>
          </div>

          <div
            class="label-input-group"
            v-if="config.push_action.action === 'deeplink'"
          >
            <span class="new-ui-label">
              {{ $t('inboxPushActionGoToDeepLink') }}
            </span>
            <InputText
              type="text"
              v-model="config.push_action.deeplink"
              :disabled="!isAvailable"
            />
          </div>

          <div v-if="isAvailable" class="form-group">
            <label>{{ $t('inboxUploadImageLabel') }}</label>
            <div style="display: flex">
              <label
                style="flex: 1; margin-right: 10px"
                class="btn btn-default btn-file"
                :class="{ disabled: processingImageUpload }"
              >
                {{ $t('uploadLabel') }}
                <input
                  type="file"
                  accept="image/png, image/jpeg, image/gif"
                  style="display: none"
                  @change="uploadImage($event)"
                  :disabled="processingImageUpload"
                />
              </label>
              <button
                style="flex: 1; margin-left: 10px"
                class="btn btn-default"
                :disabled="!config.push_icon"
                @click="removeImage()"
              >
                {{ $t('removeLabel') }}
              </button>
            </div>
          </div>

          <div class="payload-ct">
            <span class="new-ui-label">{{ $t('payloadLabel') }}</span>
            <div
              v-for="(payload, index) in config.payload_add"
              class="payload-group"
            >
              <InputText
                style="flex: 1"
                v-model="config.payload_add[index].key"
                :placeholder="$t('keyPlaceholderLabel')"
                :disabled="!isAvailable"
              />
              <InputText
                style="flex: 1"
                v-model="config.payload_add[index].value"
                :placeholder="$t('valuePlaceholderLabel')"
                :disabled="!isAvailable"
              />
              <span
                v-if="isAvailable"
                v-tooltip:bottom="$t('inboxToolboxLargeRemovePayloadTooltip')"
                @click="removePayload(index)"
                class="payload-remove"
              >
                <icon glyph="trash" />
              </span>
            </div>
            <div class="payload-add">
              <Button
                class="p-button-outlined"
                :label="$t('addPayloadLabel')"
                @click="addPayload"
                :disabled="isPayloadBtnDisabled()"
              />
            </div>
          </div>

          <div class="persist-content-ct">
            <div class="label-input-group">
              <span class="new-ui-label">
                {{ $t('inboxToolboxLargePersistentContentLabel') }}
              </span>
              <Dropdown
                v-model="config.persist_content"
                @change="setPersistContent"
                :options="persistInboxContentOptions"
                option-label="label"
                option-value="value"
                :disabled="!isAvailable"
              />
            </div>
            <div class="persist-content-ct">
              <div v-show="config.persist_content === 'until'">
                <span class="new-ui-label">
                  {{ $t('inboxToolboxLargePersistentContentRemoveOnOption') }}
                </span>
                <div class="input-group date" ref="dateTimePicker">
                  <input
                    type="text"
                    class="form-control"
                    :disabled="!isAvailable"
                    :placeholder="$t('selectADatePlaceholder')"
                  />
                  <span class="input-group-addon">
                    <icon glyph="date" />
                  </span>
                  <span
                    v-if="
                      config.persist_content_until_date_timezone &&
                      config.persist_content_until_date_timezone !== 'profile'
                    "
                    class="input-group-addon"
                  >
                    {{
                      config.persist_content_until_date_timezone === 'project'
                        ? config.project_timezone
                        : config.persist_content_until_date_timezone
                    }}
                  </span>
                </div>
              </div>

              <div
                v-show="config.persist_content === 'until'"
                :class="{ 'no-bottom': config.persist_content === 'until' }"
              >
                <span class="new-ui-label">
                  {{ $t('timeZoneLabel') }}
                </span>
                <timezone-picker
                  v-model="config.persist_content_until_date_timezone"
                  :disabled="!isAvailable"
                  :bespoke-options="bespokeOptions"
                  class="form-control"
                  @input="setTimezone($event)"
                />
              </div>

              <div
                class="label-input-group"
                v-show="config.persist_content === 'for'"
              >
                <span class="new-ui-label">
                  {{ $t('numberLabel') }}
                </span>
                <InputText
                  type="number"
                  v-model="config.persist_content_for_n"
                  :disabled="!isAvailable"
                />
              </div>

              <div
                v-show="config.persist_content === 'for'"
                class="label-input-group"
              >
                <span class="new-ui-label">
                  {{ $t('timeUnitLabel') }}
                </span>
                <Dropdown
                  :options="persistContentForUntilOptions"
                  v-model="config.persist_content_for_unit"
                  option-label="label"
                  option-value="value"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- Preview Section -->
        <div>
          <div class="action-ct" style="text-align: right">
            <el-dropdown v-if="!readOnly" trigger="click">
              <div class="action-btn">
                <span>{{ $t('actions') }}</span>
                <icon style="margin-bottom: 2px;" glyph="chevron-down" size="14px"/>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <div @click="setDialogVisible = true">
                    <i class="pi pi-file" />
                    {{ $t('applyTemplate') }}
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <apply-template-dialog
              :visible="setDialogVisible"
              @setVisible="setDialogVisible = false"
              :channel="MessageTypes.INBOX"
              :has-content="hasContent"
              @applyMessageTemplate="importTemplate"
            />
          </div>
          <div class="preview">
            <inbox-message-preview
              :message="formattedMessage"
              :message-type="config.inbox_type"
              :message-style="messageStyles"
              :global-styles="globalStyles"
            />
          </div>
          <div class="template-summary" v-tooltip:top="formatTemplateTooltip">
            {{ formatTemplateInfo }}
          </div>
        </div>
      </div>
    </TabPanel>
  </TabView>
</template>

<script>
import Tabs from '@/components/TabSet'
import Tab from '@/components/Tab'
import Redactor from '@/components/Redactor'
import MxaApi from '@/libs/MxaApi'
import * as Toastr from 'toastr'
import alert from '@/libs/Alert'
import SelectBarcodeType from '@/components/SelectBarcodeType'
import SelectBarcodePosition from '@/components/SelectBarcodePosition'
import InboxMessagePreview from '@/views/WorkflowEdit/ToolboxesLarge/Inbox/InboxMessagePreview.vue'
import ColorPickerField from '@/components/ColorPickerField.vue'
import LanguageSelector from '@/components/LanguageSelector'
import MultiLanguageSelector from '@/components/MultiLanguageSelector'
import ISO6391 from 'iso-639-1'
import TimezonePicker from '@/components/TimezonePicker'
import '@/libs/bootstrap-datepicker/bootstrap-datetimepicker'
import $ from 'jquery'
import NationalExclusionCheckSettings from '@/components/NationalExclusionCheckSettings'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Dropdown from 'primevue/dropdown'
import InputText from 'primevue/inputtext'
import InputSwitch from 'primevue/inputswitch'
import Button from 'primevue/button'
import ColorPicker from 'primevue/colorpicker'
import InputNumber from 'primevue/inputnumber'
import ApplyTemplateDialog from '@/components/ApplyTemplateDialog'
import { MessageTypes } from './MessageTypesEnum'
import XpApi from '@/libs/XpApi'
import moment from 'moment/moment'

export default {
  name: 'Inbox',

  components: {
    ApplyTemplateDialog,
    NationalExclusionCheckSettings,
    Tabs,
    Tab,
    LanguageSelector,
    MultiLanguageSelector,
    InboxMessagePreview,
    ColorPickerField,
    Redactor,
    TimezonePicker,
    SelectBarcodeType,
    SelectBarcodePosition,
    TabView,
    TabPanel,
    Dropdown,
    InputText,
    InputSwitch,
    Button,
    ColorPicker,
    InputNumber
  },

  props: ['action', 'readOnly', 'workflow'],

  inject: ['workflowApi'],

  mounted() {
    $(this.$refs.dateTimePicker)
      .datetimepicker({
        timeZone: this.getProjectTimeZone,
        format: 'D MMMM YYYY h:mm A',
        allowInputToggle: true,
        widgetPositioning: {
          horizontal: 'left',
          vertical: 'top'
        }
      })
      .on('dp.hide', this.saveOptions)

    this.dateTimePicker = $(this.$refs.dateTimePicker).data('DateTimePicker')

    this.updateDateTimePicker()
  },

  data() {
    const config = Object.assign(
      {
        exclusion: {
          check: false,
          category: null
        },
        inbox_type: 'alert',
        push_action: {
          action: '',
          url: '',
          deeplink: '',
          url_blank: false
        },
        push_icon: null,
        payload_add: [],
        inbox_style: {
          bg: '',
          title_bg: ''
        },
        persist_content: 'forever',
        persist_content_until_date: '',
        persist_content_until_date_timezone: 'project',
        project_timezone: this.$store.state.app.project.projectTimezone || '',
        persist_content_for_n: '',
        persist_content_for_unit: '',
        voucher_code_enabled: 0,
        voucher_code_position: 1
      },
      JSON.parse(JSON.stringify(this.action.config))
    )

    return {
      config,
      redactorPlugins: [
        'alignment',
        'fontsize',
        'fontcolor',
        'fontfamily',
        'emojis',
        'personalization',
        'snippet'
      ],
      redactorButtons: ['bold', 'italic', 'underline'],
      redactorConfig: {
        tabKey: false,
        enterKey: true,
        minHeight: 50,
        maxHeight: 350
      },
      processingImageUpload: false,
      bespokeOptions: {
        project: this.$t('timeZoneProjectTimezone'),
        profile: this.$t('timeZoneProfileTimezone')
      },
      inboxTypeOption: [
        {
          value: 'alert',
          label: this.$t('inboxLargeToolboxInboxTypeAlertLabel')
        },
        { value: 'card', label: this.$t('inboxToolboxLargeInboxTypeCardLabel') }
      ],
      defaultActionOptions: [
        { value: '', label: this.$t('inboxDefaultActionNoneLabel') },
        { value: 'url', label: this.$t('inboxDefaultActionOpenUrlLabel') },
        { value: 'deeplink', label: this.$t('inboxDefaultActionGoToDeeplink') }
      ],
      persistInboxContentOptions: [
        {
          value: 'forever',
          label: this.$t('inboxToolboxLargePersistentContentDontRemoveOption')
        },
        {
          value: 'until',
          label: this.$t('inboxToolboxLargePersistentContentRemoveOnOption')
        },
        {
          value: 'for',
          label: this.$t('inboxToolboxLargePersistentContentRemoveAfterOption')
        }
      ],
      persistContentForUntilOptions: [
        { value: 'minute', label: this.$t('timeUnitMinutesOption') },
        { value: 'hour', label: this.$t('timeUnitHoursOption') },
        { value: 'day', label: this.$t('timeUnitDaysOption') },
        { value: 'week', label: this.$t('timeUnitWeeksOption') },
        { value: 'month', label: this.$t('timeUnitMonthsOption') }
      ],
      messagePreviewLanguage: '',
      panelKeyState: 1,
      MessageTypes,
      setDialogVisible: false
    }
  },

  beforeDestroy() {
    if (this.isAvailable) {
      const config = JSON.parse(JSON.stringify(this.config))
      this.workflowApi.updateAction(this.action.id, { config })
    }
  },

  computed: {
    getProjectTimeZone() {
      return this.$store.state.app.project.projectTimezone || ''
    },

    languages() {
      return this.config.languages
    },

    additionalLanguages() {
      return this.config.languages.filter(
        (lang) => lang !== this.config.defaultLang
      )
    },

    sortedLanguages() {
      return [this.config.defaultLang, ...this.additionalLanguages]
    },

    isAvailable() {
      return !this.readOnly && this.$store.getters.inboxEnabled
    },

    formattedMessage() {
      let language = this.messagePreviewLanguage || this.config.defaultLang
      return {
        title: this.config.messages[language].push_title || '',
        text: this.messageText,
        icon: this.config.push_icon || ''
      }
    },

    messageStyles() {
      if (this.config.inbox_type === 0) {
        return {}
      }
      return {
        bg: this.config.inbox_style.bg,
        title_bg: this.config.inbox_style.title_bg
      }
    },

    globalStyles() {
      return {}
    },

    voucherCodesEnabled() {
      return (
        (this.workflow?.features?.voucher_codes || false) &&
        ((this.config?.voucher_code_enabled || 0) === 1 ||
          this.$store.getters.isFeatureEnabled('allowVoucherCodes'))
      )
    },

    messageText() {
      let language = this.messagePreviewLanguage || this.config.defaultLang
      const messageText =
        this.config.messages[language].push_text ||
        (this.config.inbox_type === 'card'
          ? this.$t('inboxToolboxLargeMessageTextHere')
          : '')

      if ((this.config.voucher_code_enabled || 0) === 0) return messageText

      if (messageText === '') return 'X6RgzCXb'

      if (this.config.voucher_code_position === 0) {
        return '<p>X6RgzCXb</p>' + messageText
      }

      return messageText + '<p>X6RgzCXb</p>'
    },

    getKey() {
      // @todo review this issue, its not a good way to be using these keys
      // The prime vue tabs are not reactive between each tab click
      // If the default language changes or a language is removed it does is not reactive
      // so this key maintains reactivity
      return this.config.defaultLang + this.config.languages.length
    },

    hasContent() {
      return !!(this.config.messages?.[this.getCurrentLanguage]?.push_title
        || this.config.messages?.[this.getCurrentLanguage]?.push_text
        || this.config.push_icon
        || this.config.inbox_style.title_bg
        || this.config.inbox_style.bg
        || this.config.payload_add.length > 0
        || this.config.push_action.action)
    },

    getCurrentLanguage() {
      return this.messagePreviewLanguage || this.config.defaultLang
    },

    formatTemplateTooltip() {
      const whoApplied = this.action.config.templateInfo?.[this.getCurrentLanguage]?.whoApplied
      if (!whoApplied) {
        return ''
      }

      const lastApplied = moment(this.action.config.templateInfo?.[this.getCurrentLanguage]?.lastApplied)
        .format('DD/MMM/YYYY HH:mm')

      return this.$t('templateAppliedBy', {
        whoApplied: whoApplied,
        lastApplied: lastApplied
      })
    },

    formatTemplateInfo() {
      const templateTitle = this.action.config.templateInfo?.[this.getCurrentLanguage]?.templateTitle
      if (!templateTitle) {
        return ''
      }

      return this.$t('templateCreatedFrom') + templateTitle
    }
  },

  methods: {
    saveOptions() {
      const config = this.config
      config.persist_content_until_date = this.getPersistUntil()
      this.workflowApi.updateAction(this.action.id, { config })
    },

    getPersistUntil() {
      const date = this.dateTimePicker.date()

      if (!date) {
        return ''
      }

      return date.format('YYYY-MM-DD HH:mm')
    },

    updateDateTimePicker() {
      if (this.config.persist_content_until_date) {
        this.dateTimePicker.date(
          new Date(this.config.persist_content_until_date)
        )
      }
    },

    setDefaultLang(lang) {
      if (!this.config.languages.includes(lang)) {
        this.config.languages.push(lang)
      }

      this.config.defaultLang = lang
    },

    setLanguages(languages) {
      if (!languages.includes(this.config.defaultLang)) {
        languages.push(this.config.defaultLang)
      }
      this.config.languages = languages
    },

    updateInboxType(type) {
      if (type === 'alert') {
        this.config.inbox_style.bg = ''
        this.config.inbox_style.title_bg = ''
      }
    },

    setDefaultAction({ value }) {
      if (!value) {
        this.config.push_action.url = ''
        this.config.push_action.deeplink = ''
      }

      if (value === 'url') {
        this.config.push_action.deeplink = ''
      }

      if (value === 'deeplink') {
        this.config.push_action.url = ''
      }
    },

    setPersistContent({ value }) {
      switch (value) {
        case 'until':
          this.config.persist_content_for_n = ''
          this.config.persist_content_for_unit = ''
          this.config.persist_content_until_date_timezone =
            this.getProjectTimeZone
          break
        case 'for':
          this.config.persist_content_until_date = ''
          this.config.persist_content_until_date_timezone = ''
          break
        default:
          this.config.persist_content_until_date = ''
          this.config.persist_content_for_n = ''
          this.config.persist_content_for_unit = ''
          this.config.persist_content_until_date_timezone = ''
      }
    },

    setTimezone(timezoneOption) {
      if (timezoneOption === 'project') {
        this.config.project_timezone = this.getProjectTimeZone
      } else {
        this.config.project_timezone = ''
      }
    },

    uploadImage(event) {
      this.processingImageUpload = true

      const projectId = this.$store.state.app.project.id,
        data = new FormData()

      data.append('image', event.target.files[0])
      event.target.value = null

      MxaApi.post(`projects/${projectId}/images`, data)
        .then(({ data }) => {
          this.config.push_icon = data.url
          this.processingImageUpload = false
        })
        .catch(({ data }) => Toastr.error(data.title))
    },

    removeImage() {
      this.config.push_icon = null
    },

    addPayload() {
      if (this.config.payload_add === null) {
        this.config.payload_add = []
      }

      this.config.payload_add.push({ key: '', value: '' })
    },

    removePayload(index) {
      if (
        this.config.payload_add[index].key === '' &&
        this.config.payload_add[index].value === ''
      ) {
        this.config.payload_add.splice(index, 1)
        return
      }

      alert.show({
        confirm: true,
        type: 'warning',
        okText: this.$t('inboxToolboxLargeDeleteAlertOkText'),
        title: this.$t('inboxToolboxLargeDeleteAlertTitle'),
        htmlMessage: true,
        message: this.$t('inboxToolboxLargeDeleteAlertMessage'),
        onOk: (resolve) => {
          this.config.payload_add.splice(index, 1)
          resolve()
        }
      })
    },

    isPayloadBtnDisabled() {
      if (!this.isAvailable) {
        return true
      }

      if (this.config.payload_add === null) {
        return false
      }

      if (!this.config.payload_add[this.config.payload_add.length - 1]) {
        return false
      }

      return (
        this.config.payload_add[this.config.payload_add.length - 1].key ===
          '' ||
        this.config.payload_add[this.config.payload_add.length - 1].value === ''
      )
    },
    getLanguage(languageCode) {
      return ISO6391.getName(languageCode)
    },

    setPreviewLanguage({ index }) {
      this.messagePreviewLanguage = this.sortedLanguages[index]
    },

    generateKey() {
      // @todo review this issue, its not a good way to be using these keys
      // The prime vue tabs are not reactive between each tab click
      // If the default language changes or a language is removed it does is not reactive
      // so this key maintains reactivity
      // This will make sure we go to the first tab as that will always be the default language tab
      this.panelKeyState += this.panelKeyState
      this.messagePreviewLanguage = ''
    },

    importTemplate(template) {
      if (this.readOnly) {
        return
      }

      this.setDialogVisible = false
      const projectId = this.$store.state.app.project.id
      XpApi.get(`projects/${projectId}/campaign-templates/${template.id}`, {
        params: { fields: 'id,title', lang: this.getCurrentLanguage, expand: 'message' }
      }).then(({ data: template }) => {
        const message = template.message
        this.config.inbox_type = message.inbox_type === 1 ? 'card' : 'alert'
        this.config.messages[this.getCurrentLanguage].push_title = message.push_title
        this.config.messages[this.getCurrentLanguage].push_text = message.push_text
        this.config.push_icon = message.push_icon
        this.config.inbox_style = message.inbox_style
        this.config.payload_add = message.payload_add
        this.config.push_action = message.push_action

        if (this.config.push_action.hasOwnProperty('url_blank')) {
          this.config.push_action.url_blank = Boolean(Number(this.config.push_action.url_blank))
        }

        this.config.templateInfo = this.config.templateInfo || {}
        this.config.templateInfo[this.getCurrentLanguage] = {
          templateId: template.id,
          templateTitle: template.title,
          whoApplied: this.$store.state.app.user.email,
          lastApplied: new Date().getTime()
        }

        const config = JSON.parse(JSON.stringify(this.config))
        this.workflowApi.updateAction(this.action.id, { config })
      })
    }
  },

  watch: {
    languages: {
      immediate: true,
      handler(languages) {
        let changes = false
        languages.forEach((lang) => {
          if (!this.config.messages[lang]) {
            this.$set(this.config.messages, lang, {
              push_title: '',
              push_text: ''
            })
            changes = true
          }
        })

        Object.keys(this.config.messages).forEach((lang) => {
          if (!languages.includes(lang)) {
            this.$delete(this.config.messages, lang)
            changes = true
          }
        })
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.content
    overflow: scroll
    position: absolute
    top: 0
    bottom: 0
    right: 0
    left: 0
    padding: 20px 600px 20px 20px

    .language-header
        font-weight: bold
        font-size: 14px
        margin-top: 10px
        margin-bottom: 10px

    .colorpicker-container
        display: flex
        justify-content: space-between

    .payload-group
        display: flex
        margin-bottom: 20px

        .payload-value
            margin-left: 20px

        .payload-remove
            font-size: 20px
            margin-top: -10px
            display: flex
            align-items: center
            cursor: pointer
            margin-left: 10px

    .payload-add
        width: 100%

.preview
    display: flex
    justify-content: center
    margin-top: 25px
    background: #ececec
    padding: 50px 50px 50px 50px
    height: fit-content

.card
    background-color: #e6e6e6
    padding: 15px
    border: 1px solid transparent
    border-radius: 4px
    box-shadow: 0 1px 1px rgba(0,0,0,.05)

    .form-group.no-bottom
        margin-bottom: 0px

.VoucherOption
    &:first-of-type
        margin-top: 15px

    display: flex
    flex-direction: column
    padding-right: 0
    padding-left: 0
    margin-bottom: 15px


.setup-tab-ct
  display: flex
  flex-direction: column
  gap: 20px
  width: 50%

.label-input-group
  display: flex
  flex-direction: column
  gap: 7px

.new-ui-label
  color: #4b515f
  font-weight: 600

.new-ui-label-width
  color: #4b515f
  font-weight: 600
  width: 200px

.content-section-ct
  display: flex
  gap: 120px

.content-section-wrapper
  width: 50%
  display: flex
  flex-direction: column
  gap: 20px

.label-input-group
  display: flex
  flex-direction: column
  gap: 7px

.language-content
  display: flex
  flex-direction: column
  gap: 20px

.label-switch-group
  display: flex
  align-items: center
  gap: 20px

.payload-group
  display: flex
  align-items: center
  gap: 20px

.payload-ct
  display: flex
  flex-direction: column
  gap: 7px

.color-picker
  display: flex
  justify-content: space-between
  align-items: center

.color-picker-ct
  display: flex
  align-items: center
  gap: 7px

.input-group-addon
  background-color: #ffffff

.persist-content-ct
  display: flex
  flex-direction: column
  gap: 20px

.action-btn
  display: flex
  color: #2563EB
  font-size: 14px
  font-weight: 700
  height: 40px
  gap: 10px
  align-items: center
  padding-bottom: 5px
  user-select: none

.el-popper
  margin-top: 0 !important

.template-summary
  user-select: none
  float: right

.p-button:focus
  box-shadow: none
</style>
