<template>
  <div>
    <div class="form-group">
      <label class="control-label">{{ $t('promotionLabel') }}:</label>
      <div>
        <el-autocomplete
          :debounce="500"
          :placeholder="placeholder"
          :disabled="readOnly || !loaded"
          v-model="selectedPromotionName"
          :fetch-suggestions="querySearchPromotions"
          @select="onSelect"
        />
      </div>
    </div>

    <div v-if="promotion.id" class="form-group">
      <label class="control-label">{{ $t('promotionActionLabel') }}:</label>
      <multiselect
        placeholder="Select Action"
        :options="events"
        :value="selectedEvent"
        label="label"
        :disabled="readOnly"
        @input="onInput"
      >
        <template #noOptions>
          {{ $t('vueMultiSelectNoOptions') }}
        </template>
      </multiselect>
    </div>

    <div v-if="action.promotion_amount_required" class="form-group">
      <label class="control-label">{{ $t('promotionAmountLabel') }}:</label>
      <div style="margin-bottom: 10px">
        <Dropdown
          v-model="amountType"
          :disabled="readOnly"
          :options="promotionAmountOptions"
          optionLabel="label"
          optionValue="value"
          @input="onAmountTypeChange"
        />
      </div>

      <InputNumber
        v-if="amountType === 'custom'"
        v-model="action.promotion_amount"
        :min="0"
        :max="9999999999"
        :max-fraction-digits="2"
        :disabled="readOnly"
        showButtons
        @blur="onAmountInput"
        :useGrouping="false"
      />
      <multiselect
        v-if="amountType === 'attribute'"
        :placeholder="$t('selectProfileAttributePlaceholder')"
        :select-label="$t('vueMultiSelectSelectLabel')"
        :selected-label="$t('vueMultiSelectSelectedLabel')"
        :deselect-label="$t('vueMultiSelectDeselectLabel')"
        :deselect-title="$t('vueMultiSelectDeselectTitle')"
        :no-results="$t('vueMultiSelectNoResults')"
        :value="selectedNumberProfileAttribute"
        :options="numberProfileAttributes"
        track-by="id"
        label="name"
        :disabled="readOnly"
        @input="onAmountAttributeChange"
      >
        <template #noOptions>
          {{ $t('vueMultiSelectNoOptions') }}
        </template>
      </multiselect>
    </div>

    <div v-if="action.promotion_duration_required" class="form-group">
      <label class="control-label">{{ $t('promotionDurationLabel') }}:</label>
      <div style="margin-bottom: 10px">
        <InputNumber
          v-model="action.promotion_duration"
          showButtons
          :disabled="readOnly"
          :min="0"
          :max="2147483647"
          :useGrouping="false"
          @blur="onDurationChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import XpApi from "@/libs/XpApi";

import InputNumber from 'primevue/inputnumber'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'

export default {
  name: 'Promotion',
  inject: ['workflowApi'],
  props: ['action', 'readOnly'],

  components: {
    Dropdown,
    InputNumber,
    InputText
  },

  data() {
    return {
      event: [
        { id: 1, label: this.$t('promotionEventAddLabel') },
        { id: 2, label: this.$t('promotionEventRemoveLabel') },
        { id: 3, label: this.$t('promotionEventCreditLabel') }
      ],
      amountType:
        this.action.promotion_amount_attribute_title &&
        this.action.promotion_amount_attribute_title.length
          ? 'attribute'
          : 'custom',
      promotion: {},
      selectedPromotionName: '',
      loaded: false,
      promotionAmountOptions: [
        {
          label: this.$t('promotionAmountCustomAmountOption'),
          value: 'custom'
        },
        {
          label: this.$t('promotionAmountProfileAttributeOption'),
          value: 'attribute'
        }
      ]
    }
  },

  computed: {
    placeholder() {
      return this.loaded
          ? this.$t('selectOptionPlaceholder')
          : this.$t('loadingPlaceholder')
    },
    events() {
      let promotion = this.$store.state.project.workflowPromotions.find(
        (promotion) => promotion.id == this.action.promotion_id
      )
      if (!promotion) {
        XpApi.get(`projects/${this.$route.params.projectId}/promotions`, {
          params: {
            id: this.action.promotion_id,
            for: 'workflow',
            expand: 'can_add,can_remove,integration_name'
          }
        }).then(({ data }) => {
          if (data) {
            promotion = data[0]
          }
        })
      }
      let event = []
      if (promotion) {
        if (promotion.can_add) {
          event.push({ id: 1, label: this.$t('promotionEventAddLabel') })
        }
        if (promotion.can_remove) {
          event.push({ id: 2, label: this.$t('promotionEventRemoveLabel') })
        }
        if (promotion.can_credit) {
          event.push({ id: 3, label: this.$t('promotionEventCreditLabel') })
        }
      }
      return event
    },

    selectedEvent() {
      return this.event.find((event) => event.id === this.action.event)
    },

    numberProfileAttributes() {
      return this.$store.state.project.numberProfileAttributes
    },

    selectedNumberProfileAttribute() {
      return this.$store.state.project.numberProfileAttributes.find(
        (attr) => attr.name === this.action.promotion_amount_attribute_title
      )
    }
  },

  created() {
      if (this.action.promotion_id) {
        const foundPromotion =
            this.$store.state.project.workflowPromotions.find((promotion) => promotion.id == this.action.promotion_id)
        if (foundPromotion === undefined) {
          XpApi.get(`projects/${this.$route.params.projectId}/promotions`, {
            params: {
              id: this.action.promotion_id,
              for: 'workflow',
              expand: 'can_add,can_remove,integration_name'
            }
          }).then(({ data }) => {
            if (data) {
              this.promotion = data[0]
              this.selectedPromotionName = this.promotionsLabel(data[0])
            }
          })
        } else {
          this.promotion = foundPromotion
          this.selectedPromotionName = this.promotionsLabel(foundPromotion)
        }
      }
    this.$store.dispatch('fetchNumberProfileAttributes')
    this.loaded = true
  },

  methods: {
    promotionsLabel(option) {
      return option.integration_name + " (#" + option.external_id + ") - " + option.name
    },

    onSelect(option) {
      if (!option) {
        return
      }

      this.promotion = option
      this.selectedPromotionName = option ? this.promotionsLabel(option) : ''

      let event = this.defaultPromotionEvent(option)
      let action = this.promotionActionName(event)

      this.workflowApi.updateAction(this.action.id, {
        promotion_id: option.id,
        event: event,
        promotion_amount: 0,
        promotion_amount_attribute_title: null,
        promotion_duration: 0,
        promotion_amount_required: this.isParamRequired(
          option,
          action,
          'amount'
        ),
        promotion_duration_required: this.isParamRequired(
          option,
          action,
          'duration'
        )
      })
    },

    onInput(option) {
      if (!option) {
        return
      }

      let action = this.promotionActionName(option.id)
      this.workflowApi.updateAction(this.action.id, {
        event: option.id,
        promotion_amount: 0,
        promotion_amount_attribute_title: null,
        promotion_duration: 0,
        promotion_amount_required: this.isParamRequired(
          this.promotion,
          action,
          'amount'
        ),
        promotion_duration_required: this.isParamRequired(
          this.promotion,
          action,
          'duration'
        )
      })
    },

    onAmountInput(event) {
      const value = Number(event.target.value)

      if (!value) {
        this.action.promotion_amount = 0
        return
      }

      this.action.promotion_amount = value
      this.workflowApi.updateAction(this.action.id, {
        promotion_amount: this.action.promotion_amount
      })
    },

    onAmountAttributeChange(option) {
      if (!option) {
        return
      }

      this.workflowApi.updateAction(this.action.id, {
        promotion_amount_attribute_title: option.name
      })
    },

    onAmountTypeChange() {
      this.workflowApi.updateAction(this.action.id, {
        promotion_amount: 0,
        promotion_amount_attribute_title: null
      })
    },

    onDurationChange(event) {
      const value = event.target.value

      if (!value) {
        this.action.promotion_duration = 1
        return
      }

      this.workflowApi.updateAction(this.action.id, {
        promotion_duration: this.action.promotion_duration
      })
    },

    querySearchPromotions(queryString, cb) {
      XpApi.get(`projects/${this.$route.params.projectId}/promotions`, {
        params: {
          search: queryString,
          for: 'workflow',
          expand: 'can_add,can_remove,integration_name'
        }
      }).then(({ data }) => {
        const list = data.map(item => {
          item.value = this.promotionsLabel(item)
          return item
        })
        cb(list)
      })
    },

    defaultPromotionEvent(promotion) {
      if (promotion.can_add) {
        return 1
      } else if (promotion.can_credit) {
        return 3
      } else if (promotion.can_remove) {
        return 2
      }
    },

    promotionActionName(event) {
      switch (event) {
        case 1:
          return 'add'
        case 2:
          return 'remove'
        case 3:
          return 'credit'
      }
    },

    isParamRequired(promotion, action, param) {
      if (!promotion.params || !promotion.params[param]) {
        return false
      }

      if (!action) {
        return false
      }

      return promotion.params[param].action === action
    }
  }
}
</script>
<style lang="sass" scoped>
.el-autocomplete
  width: 100%
  padding-top: 2px

.p-dropdown,
.p-inputnumber
  width: 100%
</style>
