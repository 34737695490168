<template>
  <div>
    <div class="row" v-show="!showEditor">
      <div class="col-md-5 content-tab-ct" style="padding-top: 20px">
        <div class="label-input-group">
          <span class="new-ui-label-no-width">
            {{ $t('emailSubjectLine') }}
          </span>
          <div class="input-group" v-loading="aiLoading">
            <InputText
              id="subject"
              :value="action.subject"
              @blur="updateProperty"
              type="text"
              class="form-input"
              :placeholder="$t('emailSubjectInputPlaceholder')"
              ref="subject"
              :disabled="readOnly"
            />
            <div class="input-group-btn subject-btns">
              <div
                class="open"
                v-if="showSubjectAttributes"
                v-click-outside="hideSubjectAttributes"
                style="position: absolute; right: 0; top: 32px"
              >
                <ul class="dropdown-menu dropdown-menu-right">
                  <li style="padding: 10px">
                    <profile-attributes
                      style="width: 300px"
                      :options="profileAttributes"
                      v-model="profileAttribute"
                      @input="subjectInsertAttribute"
                      :disabled="readOnly"
                    />
                  </li>
                </ul>
              </div>
              <div
                v-if="aiTextGeneration && !readOnly"
                class="btn btn-default"
                @click="getAiSubjectLine"
              >
                <icon glyph="ai" />
              </div>
              <div
                v-if="!readOnly"
                class="btn btn-default"
                @click.stop="showSubjectAttributes = true"
              >
                <span class="glyphicon glyphicon-user"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="label-input-group">
          <span class="new-ui-label-no-width">
            {{ $t('emailFromAddress') }}
          </span>
          <Dropdown
            id="fromAddress"
            class="form-dropdown"
            :filter="true"
            :value="fromAddressValue"
            @input="updateFromAddress"
            :options="fromAddresses"
            option-label="address"
            option-value="address"
            :placeholder="displayFromPlaceholder"
            :disabled="readOnly || fromAddresses.length === 0"
          />
        </div>
        <div class="label-input-group">
          <span class="new-ui-label-no-width">{{ $t('emailFromName') }}</span>
          <InputText
            id="fromName"
            class="form-input"
            type="text"
            :value="action.fromName"
            @blur="updateProperty"
            :placeholder="$t('emailFromNameInputPlaceholder')"
            :disabled="readOnly || fromAddresses.length === 0"
          />
        </div>
        <div class="label-input-group">
          <span class="new-ui-label-no-width">
            {{ $t('emailReplyToAddress') }}
          </span>
          <InputText
            id="replyToAddress"
            class="form-input"
            :value="action.replyToAddress"
            @blur="updateProperty"
            type="text"
            :placeholder="$t('emailReplyToAddressInputPlaceholder')"
            :disabled="readOnly"
          />
        </div>
        <div class="label-input-group">
          <span class="new-ui-label-no-width">{{ $t('emailReplyName') }}</span>
          <InputText
            id="replyToName"
            :value="action.replyToName"
            @blur="updateProperty"
            type="text"
            class="form-input"
            :placeholder="$t('emailReplyNameInputPlaceholder')"
            :disabled="readOnly"
          />
        </div>
        <div class="label-input-group">
          <span class="new-ui-label-no-width">
            {{ $t('emailMessageType') }}
          </span>
          <Dropdown
            id="ignore_subscription"
            class="form-dropdown"
            :value="config.ignore_subscription"
            @input="onIgnoreSubscriptionChange"
            :options="ignoreSubscriptionOptions"
            option-label="label"
            option-value="value"
            :disabled="readOnly"
          />
        </div>

        <div class="label-input-group" v-if="consentPreferencesAvailable">
          <div class="label-switch-group">
            <span class="new-ui-label">{{ $t('consentPreferenceType') }}</span>
            <InputSwitch
              :disabled="readOnly"
              v-model="config.consent_preference.enabled"
            />
          </div>
          <select-consent-preference
            v-if="config.consent_preference.enabled"
            :value="config.consent_preference.preference"
            :channel="ConsentChannels.SMS"
            :preferences="consentPreferences"
            :read-only="readOnly"
            @input="updateConsentPreference"
          />
        </div>

        <national-exclusion-check-settings
          class="label-input-group"
          v-if="
            $store.getters.isFeatureEnabled('nationalExclusionCheckBetsson') ||
            $store.getters.isFeatureEnabled('nationalExclusionCheckOasis')
          "
          v-model="config.exclusion"
          :disabled="readOnly"
        />
        <div class="label-input-group" v-if="emailAttachmentsEnabled">
          <span class="new-ui-label-no-width" v-if="!readOnly">
            {{ $t('emailAttachPdf') }}
          </span>
          <div
            v-if="!readOnly"
            @dragover="onPdfDragOver"
            @dragleave="onPdfDragLeave"
            @drop="onPdfDragDrop"
            class="dropzone"
          >
            <p>{{ $t('emailDragToUploadPdf') }}</p>
            <label class="btn btn-file btn-default">
              <icon glyph="cloud-upload" />
              {{ $t('emailAttachPdfBrowse') }}
              <input type="file" accept=".pdf" @change="importFile" />
            </label>
          </div>
          <div v-for="(attachment, index) in config.attachments" :key="index">
            <div v-if="attachment">
              <label>
                {{ $t('emailAttachedFile') }} {{ attachment.fileName }}
              </label>
              <Button
                v-if="!readOnly"
                :label="$t('emailAttachedFileDelete')"
                @click="clearFile(index)"
                class="p-button-outlined"
                style="height: 30px; line-height: 0"
              />
            </div>
          </div>
          <div v-if="config.fileName != null">
            <label>{{ $t('emailAttachedFile') }} {{ config.fileName }}</label>
            <button v-if="!readOnly" @click="clearFileLegacy">
              {{ $t('emailAttachedFileDelete') }}
            </button>
          </div>
        </div>
        <div
          class="label-input-group"
          v-if="config.hasOwnProperty('promotion_id')"
        >
          <span class="new-ui-label-no-width">
            {{ $t('emailPromotionLabel') }}
          </span>
          <div>
            <el-autocomplete
              clearable
              :debounce="500"
              :placeholder="placeholder"
              :disabled="readOnly || !loaded"
              v-model="selectedPromotionName"
              :fetch-suggestions="querySearchPromotions"
              @select="updatePromotion"
              @clear="updatePromotion({})"
            />
          </div>
        </div>
        <div
          class="label-input-group"
          v-if="
            config.hasOwnProperty('promotion_amount_required') &&
            config.promotion_amount_required
          "
        >
          <span class="new-ui-label-no-width">
            {{ $t('emailPromotionAmountLabel') }}
          </span>
          <InputNumber
            mode="decimal"
            :use-grouping="false"
            :max-fraction-digits="2"
            class="form-input"
            v-model="config.promotion_amount"
            @blur="updateConfig"
            :disabled="readOnly"
          />
        </div>
        <div
          class="label-input-group"
          v-if="
            config.hasOwnProperty('promotion_duration_required') &&
            config.promotion_duration_required
          "
        >
          <span class="new-ui-label-no-width">
            {{ $t('emailPromotionDurationLabel') }}
          </span>
          <InputNumber
            :use-grouping="false"
            class="form-input"
            v-model="config.promotion_duration"
            @blur="updateConfig"
            :disabled="readOnly"
          />
        </div>
      </div>
      <div class="col-md-7" style="padding-top: 5px; padding-right: 0">
        <div class="action-ct" style="text-align: right">
          <el-dropdown v-if="!readOnly" trigger="click">
            <div class="action-btn">
              <span>{{ $t('actions') }}</span>
              <icon
                style="margin-bottom: 2px"
                glyph="chevron-down"
                size="14px"
              />
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <div @click="setDialogVisible = true">
                  <i class="pi pi-file" />
                  {{ $t('applyTemplate') }}
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <apply-template-dialog
            :visible="setDialogVisible"
            @setVisible="setDialogVisible = false"
            :channel="MessageTypes.EMAIL"
            :content-id="action.contentId"
            :preselected-template="{}"
            @applyMessageTemplate="importTemplate"
          />
        </div>
        <div class="preview">
          <iframe
            v-if="action.contentId > 0"
            class="iframe-seamless"
            width="100%"
            height="100%"
            :srcdoc="content"
          />
          <div v-if="action.contentId > 0" class="btn-section">
            <Button
              :disabled="readOnly"
              :label="$t('editBtn')"
              @click="prepareEdit()"
            />
            <Button
              :disabled="readOnly"
              :label="$t('deleteBtn')"
              @click="onDelete"
              class="p-button-outlined"
            />
          </div>
          <div v-else class="btn-section">
            <Button
              :disabled="readOnly"
              :label="$t('emailCreateWithBuilder')"
              @click="prepareBuilder()"
            />
            <Button :disabled="readOnly" @click="$refs.fileInput.click()">
              <span style="font-weight: 700">{{ $t('Upload HTML') }}</span>
              <input
                type="file"
                style="display: none"
                ref="fileInput"
                accept="text/html"
                @change="importHtmlFile"
              />
            </Button>
          </div>
        </div>
        <div class="template-summary" v-tooltip:top="formatTemplateTooltip">
          {{ formatTemplateInfo }}
        </div>
      </div>
    </div>
    <component
      v-if="showEditor && !readOnly"
      :is="editorComponent"
      :workflow="workflow"
      :action="action"
      @close="onClose"
      :read-only="readOnly"
    />
  </div>
</template>
<script>
import ProfileAttributes from '@/components/ProfileAttributes'
import SelectConsentPreference from '@/components/SelectConsentPreference'

import Upload from './Email/Upload'
import Builder from './Email/Builder'
import Editor from './Email/Editor'
import vClickOutside from 'v-click-outside'
import SelectPromotion from '@/components/SelectPromotion'
import * as Toastr from 'toastr'
import XpApi from '@/libs/XpApi'
import IgnoreSubscription from '@/components/IgnoreSubscription'
import NationalExclusionCheckSettings from '@/components/NationalExclusionCheckSettings.vue'
import { ConsentChannels } from '@/enums/ConsentChannelEnum'
import _ from 'lodash'

import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import Dropdown from 'primevue/dropdown'
import InputSwitch from 'primevue/inputswitch'
import Button from 'primevue/button'
import Menu from 'primevue/menu'
import FileUpload from 'primevue/fileupload'
import ImportMessages from './Email/Dialog/ImportMessages.vue'
import VueDialog from '@/libs/VueDialog'
import alert from '@/libs/Alert'
import BlankTemplate from './Email/Builder/BlankTemplate'
import ApplyTemplateDialog from '@/components/ApplyTemplateDialog'
import { MessageTypes } from './MessageTypesEnum'
import moment from 'moment/moment'

export default {
  props: ['action', 'readOnly', 'workflow'],

  inject: ['workflowApi', 'contentApi'],

  components: {
    ApplyTemplateDialog,
    IgnoreSubscription,
    SelectPromotion,
    Upload,
    Builder,
    Editor,
    InputText,
    InputNumber,
    TabPanel,
    TabView,
    ProfileAttributes,
    NationalExclusionCheckSettings,
    SelectConsentPreference,
    Dropdown,
    InputSwitch,
    Button,
    Menu,
    FileUpload
  },

  directives: {
    clickOutside: vClickOutside.directive
  },

  data() {
    const config = Object.assign(
      {
        exclusion: {
          check: false,
          category: null
        },
        pdf: null,
        fileName: null,
        attachments: [],
        ignore_subscription: 'doNotIgnore',
        consent_preference: {
          enabled: false,
          preference: null
        }
      },
      JSON.parse(JSON.stringify(this.action.config))
    )

    return {
      promotion: {},
      selectedPromotionName: '',
      editorComponent: 'builder',
      content: '',
      loaded: false,
      aiLoading: false,
      showSubjectAttributes: false,
      profileAttribute: '',
      temporaryFileName: '',
      attachment: {},
      maxAttachments: 4,
      showEditor: false,
      config,
      ConsentChannels,
      setDialogVisible: false,
      MessageTypes,
      builderTemplates: null,
      ignoreSubscriptionOptions: [
        { value: 'doNotIgnore', label: this.$t('emailMessageTypeMarketing') },
        { value: 'ignore', label: this.$t('emailMessageTypeTransactional') }
      ]
    }
  },

  created() {
    this.$store.dispatch('fetchSenderDetails')
    this.$store.dispatch('fetchAttributes')
    this.$store.dispatch('fetchBuilderTemplates')
    if (this.config.promotion_id) {
      const foundPromotion = this.$store.state.project.promotions.find(
        (promotion) => promotion.id === this.config.promotion_id
      )
      if (foundPromotion === undefined) {
        XpApi.get(`projects/${this.$route.params.projectId}/promotions`, {
          params: {
            id: this.config.promotion_id,
            expand: 'can_add,can_remove,integration_name'
          }
        }).then(({ data }) => {
          if (data.length === 0) {
            this.updatePromotion({})
          } else {
            this.promotion = data[0]
            this.selectedPromotionName = this.promotionsLabel(data[0])
          }
        })
      } else {
        this.promotion = foundPromotion
        this.selectedPromotionName = this.promotionsLabel(foundPromotion)
      }
    }
    this.loaded = true
  },

  computed: {
    fromAddresses() {
      return this.$store.state.project.senderDetails
    },

    placeholder() {
      return this.loaded
        ? this.$t('selectOptionPlaceholder')
        : this.$t('loadingPlaceholder')
    },

    consentPreferences() {
      return this.$store.state.project.consentPreferences
    },

    fromAddressValue() {
      return this.action.fromAddress
    },

    displayFromPlaceholder() {
      if (!this.loaded) {
        return `${this.$t('emailFromAddressSelectLoadingPlaceholder')}`
      }

      if (this.fromAddresses.length === 0) {
        return this.$t('emailFromAddressSelectNoDetailsAvailable')
      }

      return this.$t('emailFromAddressSelectOption')
    },

    profileAttributes() {
      return this.$store.state.project.attributes
    },

    emailAttachmentsEnabled() {
      return this.$store.getters.isFeatureEnabled('emailAttachmentsEnabled')
    },

    aiTextGeneration() {
      return this.$store.getters.isFeatureEnabled('aiTextGeneration')
    },

    consentPreferencesAvailable() {
      return (
        this.action.config.ignore_subscription !== 'ignore' &&
        (this.workflow.features.consent_preferences ||
          this.$store.getters.isFeatureEnabled('consentPreferences'))
      )
    },

    formatTemplateTooltip() {
      const whoApplied = this.action.config.whoApplied
      const lastApplied = moment(this.action.config.lastApplied).format(
        'DD/MMM/YYYY HH:mm'
      )

      return this.$t('templateAppliedBy', {
        whoApplied: whoApplied,
        lastApplied: lastApplied
      })
    },

    formatTemplateInfo() {
      if (this.action.config.templateId === 0) {
        return this.$t('templateCreatedFrom') + this.action.config.templateTitle
      }

      for (let template of this.$store.state.project.builderTemplates) {
        if (this.action.config.templateId === template.id) {
          return this.$t('templateCreatedFrom') + template.title
        }
      }

      return ''
    }
  },

  watch: {
    'action.contentId': {
      immediate: true,
      handler(contentId) {
        if (contentId) {
          this.contentApi.load(contentId).then(({ content, meta }) => {
            this.content = content
            this.editorComponent = meta.editor || 'editor'
          })
        }
      }
    },
    'config.attachments': function () {
      this.updateConfig()
    },
    'config.exclusion': {
      handler() {
        this.updateConfig()
      },
      deep: true
    },
    'config.consent_preference': {
      handler() {
        this.updateConfig()
      },
      deep: true
    }
  },

  methods: {
    onDelete() {
      alert.show({
        confirm: true,
        type: 'warning',
        okText: this.$t('templateDeleteAlertOk'),
        title: this.$t('templateDeleteAlertTitle'),
        message: this.$t('templateDeleteAlertMessage'),
        onOk: (resolve) => {
          this.doDelete()
          resolve()
        }
      })
    },

    doDelete() {
      this.action.contentId = 0
      this.workflowApi.updateAction(this.action.id, {
        contentId: 0
      })
    },

    importHtmlFile(e) {
      this.processHtmlImport(e.target.files[0])
      e.target.value = ''
    },

    processHtmlImport(file) {
      if (!file) {
        this.displayErrorDialog('Failed to handle file upload.')
        return
      } else if (file.type !== 'text/html') {
        this.displayErrorDialog(
          `Wrong File Format '${file.type}'. Please provide a HTML file.`
        )
        return
      } else if (file.size > 250000) {
        this.displayErrorDialog('Specified file is too large.')
        return
      }

      const reader = new FileReader()
      reader.onloadend = (evt) => {
        if (evt.target.readyState !== FileReader.DONE) {
          return
        }

        const content = evt.target.result
        this.applyTemplate(content)
      }

      reader.readAsBinaryString(file.slice(0, file.size))
    },

    applyTemplate(html, template, id, title) {
      let saveContent = template
        ? this.contentApi.saveBuilder(html, JSON.parse(template))
        : this.contentApi.saveEditor(html)

      saveContent.then(({ meta, contentId }) => {
        if (meta.messages.length) {
          VueDialog.show(ImportMessages, { messages: meta.messages })
        }
        this.workflowApi.updateAction(this.action.id, {
          contentId: contentId,
          config: Object.assign({}, this.action.config, {
            templateId: id,
            templateTitle: title,
            whoApplied: this.$store.state.app.user.email,
            lastApplied: new Date().getTime()
          })
        })
      })
    },

    toggle(event) {
      this.$refs.menu.toggle(event)
    },
    onClose() {
      this.showEditor = false
    },
    clearFile(index) {
      this.config.attachments.splice(index, 1)
      this.temporaryFileName = ''
    },

    clearFileLegacy() {
      this.temporaryFileName = ''
      this.config.pdf = null
      this.config.fileName = null
    },

    importFile(e) {
      this.processFileImport(e.target.files[0])
      e.target.value = ''
    },

    uploadPdf(file) {
      let projectId = this.$route.params.projectId
      const data = new FormData()
      data.append('attachment', file)
      XpApi.post('projects/' + projectId + '/campaigns/attachment', data)
        .then((response) => {
          this.updatePdfDetails(response.data.file)
        })
        .catch(({ response }) => {
          this.displayErrorDialog(response.data.message)
        })
    },

    getAiSubjectLine() {
      if (this.aiLoading) {
        return
      }

      let emailText = ''
      if (this.content) {
        emailText = new DOMParser()
          .parseFromString(this.content, 'text/html')
          .body.textContent.trim()
          .replace(/\r?\n|\r|\t/g, ' ')
          .replace(/\s+/g, ' ')
      }

      if (!emailText) {
        this.displayErrorDialog(this.$t('emailAiSubjectLineError'))
        return
      }

      this.aiLoading = true
      let projectId = this.$route.params.projectId
      XpApi.post('projects/' + projectId + '/generate/subject-line', {
        type: 'email',
        param: emailText
      })
        .then((response) => {
          this.action.subject = response.data.result
          this.workflowApi.updateAction(this.action.id, {
            ['subject']: this.action.subject
          })
          this.aiLoading = false
        })
        .catch((response) => {
          this.displayErrorDialog(response.data.message)
          this.aiLoading = false
        })
    },

    displayErrorDialog(msg) {
      Toastr.error(msg)
    },

    onPdfDragOver(e) {
      e.stopPropagation()
      e.preventDefault()
      e.currentTarget.classList.add('dropzone-active')
    },

    onPdfDragLeave(e) {
      e.stopPropagation()
      e.preventDefault()
      e.currentTarget.classList.remove('dropzone-active')
    },

    onPdfDragDrop(e) {
      e.stopPropagation()
      e.preventDefault()
      e.currentTarget.classList.remove('dropzone-active')
      this.processFileImport(e.dataTransfer.files[0])
    },

    processFileImport(file) {
      if (!file) {
        this.displayErrorDialog(this.$t('emailFileImportErrorFailedToHandle'))
        return
      }
      if (this.config.attachments.length >= this.maxAttachments) {
        this.displayErrorDialog(this.$t('emailFileImportErrorFailedToUpload'))
        return
      }
      if (file.type !== 'application/pdf') {
        this.displayErrorDialog(
          this.$t('emailFileImportErrorWrongFormat', { format: file.type })
        )
        return
      }
      this.uploadPdf(file)
      this.temporaryFileName = file.name
    },

    optionsLabels(option) {
      return option.address
    },

    updateProperty(e) {
      const prop = e.target.id,
        value = e.target.value

      if (this.action[prop] !== value) {
        this.workflowApi.updateAction(this.action.id, {
          [prop]: value
        })
      }
    },

    updateConfig() {
      this.workflowApi.updateAction(this.action.id, { config: this.config })
    },

    updatePdfDetails(path) {
      this.attachment = {
        fileName: this.temporaryFileName,
        pdf: path
      }

      this.config.attachments.push(this.attachment)
    },

    updatePromotion(selectedPromotion) {
      this.promotion = selectedPromotion
      this.selectedPromotionName = selectedPromotion
        ? this.promotionsLabel(selectedPromotion)
        : ''
      this.config.promotion_id = selectedPromotion ? selectedPromotion.id : ''
      if (this.promotion) {
        this.config.promotion_amount_required = this.promotion.amount_required
        this.config.promotion_duration_required =
          this.promotion.duration_required
        if (!this.promotion.amount_required) {
          this.config.promotion_amount = null
        }
        if (!this.promotion.duration_required) {
          this.config.promotion_duration = null
        }
      } else {
        this.config.promotion_amount_required = false
        this.config.promotion_duration_required = false
      }

      this.updateConfig()
    },

    updateFromAddress(value) {
      let fromName = this.fromAddresses.find(
        (fromAddress) => fromAddress.address === value
      ).name
      let data = {
        ['fromAddress']: value,
        ['fromName']: fromName
      }

      if (
        this.action['replyToName'] === '' ||
        this.action['replyToName'] === this.action['fromName']
      ) {
        data['replyToName'] = fromName
      }

      if (
        this.action['replyToAddress'] === '' ||
        this.action['replyToAddress'] === this.action['fromAddress']
      ) {
        data['replyToAddress'] = value
      }

      this.workflowApi.updateAction(this.action.id, data)
    },

    hideSubjectAttributes() {
      this.showSubjectAttributes = false
    },

    subjectInsertAttribute(profileAttribute) {
      this.showSubjectAttributes = false
      this.profileAttribute = ''

      const input = this.$refs['subject'],
        startPos = input.selectionStart,
        endPos = input.selectionEnd,
        value = `{{ ${profileAttribute} }}`

      this.workflowApi.updateAction(this.action.id, {
        subject:
          input.value.substring(0, startPos) +
          value +
          input.value.substring(endPos, input.value.length)
      })
      this.$nextTick(() => {
        input.selectionStart = endPos + value.length
        input.selectionEnd = endPos + value.length
        input.$el.focus()
      })
    },

    onIgnoreSubscriptionChange(type) {
      this.config.ignore_subscription = type
      this.updateConfig()
    },

    querySearchPromotions(queryString, cb) {
      XpApi.get(`projects/${this.$route.params.projectId}/promotions`, {
        params: {
          search: queryString,
          expand: 'can_add,can_remove,integration_name'
        }
      }).then(({ data }) => {
        const list = data.map((item) => {
          item.value = item.name
          return item
        })
        cb(list)
      })
    },

    updateConsentPreference(settings) {
      this.config.consent_preference.preference = settings.value
      this.config.consent_preference.preference_name = settings.name
      this.updateConfig()
    },

    promotionsLabel(option) {
      if (_.isEmpty(option)) {
        return ''
      }

      return option.integration_name + " (#" + option.external_id + ") - " + option.name
    },

    prepareBuilder() {
      document.body.style.pointerEvents = 'none'
      const template = {
        title: 'Blank',
        html: '',
        template: JSON.stringify(BlankTemplate)
      }

      this.editorComponent = 'builder'
      this.importTemplate(template)
      this.showEditor = 1
    },

    prepareEdit() {
      document.body.style.pointerEvents = 'none'
      this.showEditor = 1
    },

    importTemplate(template) {
      this.setDialogVisible = false
      if (!template.id && template.template) {
        this.applyTemplate('', template.template, 0, template.title)
        return
      }

      const projectId = this.$store.state.app.project.id
      XpApi.get(`projects/${projectId}/campaign-templates/${template.id}`, {
        params: { fields: 'id,title', expand: 'message' }
      }).then(({ data: template }) => {
        this.applyTemplate(
          template.message.email_html,
          template.message.email_template,
          template.id,
          template.title
        )
      })
    }
  }
}
</script>
<style lang="sass" scoped>
#subject
  border-top-right-radius: 0
  border-bottom-right-radius: 0

.subject-btns .btn
  padding: 8.8px 15px

.el-autocomplete
  width: 100%
  padding-top: 2px

.form-dropdown, .form-input
    width: 100%

.dropzone
    text-align: center
    border: 2px dashed #ddd
    padding: 15px
    border-radius: 5px

.dropzone-active
    border: 2px dashed #888
.preview
    background-color: #f8fafc
    border-radius: 4px
    min-height: 660px
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    position: relative

.iframe-seamless
    position: absolute
    background-color: #ffffff
    width: calc(100% - 40px)
    height: 100%
    max-height: 565px
    border: 0
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1))
    left: 0
    top: 0
    margin: 20px 20px 0 20px
    box-sizing: border-box

.btn-section
  position: absolute
  bottom: 15px
  max-width: fit-content
  margin-left: auto
  margin-right: auto

.p-button
  margin-left: 10px
.p-button:focus
  box-shadow: none

.content-tab-ct
  display: flex
  flex-direction: column
  gap: 20px
  width: 40%
  padding-bottom: 40px

.new-ui-label
  color: #4b515f
  font-weight: 600
  width: 200px

.label-switch-group
  display: flex
  align-items: center
  gap: 100px

.label-input-group
  display: flex
  flex-direction: column
  gap: 7px

::v-deep .select2-container--bootstrap.select2-container--disabled .select2-selection
  background-color: unset
  opacity: 0.6
  cursor: default

#overlay_menu
  top: 0
  left: 0
  right: 0

.action-btn
  display: flex
  color: #2563EB
  font-size: 14px
  font-weight: 700
  height: 40px
  gap: 10px
  align-items: center
  padding-bottom: 5px
  user-select: none

.el-popper
  margin-top: 0 !important

.template-summary
  user-select: none
  float: right

.new-ui-label-no-width
  color: #4b515f
  font-weight: 600
</style>
