<template>
  <icon :glyph="icon" :size="size" @click="$emit('click', $event)"></icon>
</template>
<script>
const iconMap = {
  Start: 'action-start',
  TransactionalStart: 'action-start',
  Delay: 'action-delay',
  Email: 'action-email',
  Sms: 'action-sms',
  Push: 'action-push',
  Decision: 'action-decision',
  MultiDecision: 'action-multidecision',
  Split: 'action-split',
  End: 'action-end',
  AdRetarget: 'action-adretargeting',
  MultipleUser: 'multiple-user',
  AdRetargetRemove: 'action-adretargeting',
  Promotion: 'action-promotion',
  PromotionAdd: 'volume-up',
  PromotionRemove: 'volume-down',
  PromotionCredit: 'volume-high',
  Inbox: 'action-inbox',
  Spacer: 'spacer',
  WorkflowReporting: 'action-workflowreporting',
  Webhook: 'action-webhook',
}

export default {
  props: ['actionType', 'size'],

  computed: {
    icon() {
      return iconMap[this.actionType] || 'exclamation-mark-circle'
    }
  }
}
</script>
