import MxaApi from '@/libs/MxaApi'
import UndoManager from 'undo-manager'
import JobQueue from './JobQueue'
import Vue from 'vue'
import pusher from '@/providers/Pusher'
import router from '@/providers/Router'
import i18n from '@/config/i18n/index'

import ActionDefaults from './ActionDefaults'

import shortId from 'shortid'

const clone = function (data) {
  return JSON.parse(JSON.stringify(data))
}

const getChanges = function (oldData, newData) {
  let changed = {}

  Object.keys(newData).forEach((key) => (changed[key] = clone(oldData[key])))

  return changed
}

const PROMOTION_ADD = 1
const PROMOTION_REMOVE = 2
const PROMOTION_CREDIT = 3

export default Vue.extend({
  data() {
    return {
      workflow: {
        name: '',
        isOpen: false,
        isRunning: false,
        isTransactional: false,
        isSnapshot: false,
        hasBeenOpened: false,
        actions: [],
        events: [],
        features: []
      },
      status: {
        saving: false,
        isValid: false,
        messages: []
      },
      history: {
        hasUndo: false,
        hasRedo: false
      },
      members: {
        loading: true,
        members: []
      },
      actionTypeMap: {
        Email: 'actionTitleEmail',
        Sms: 'actionTitleSms',
        Push: 'actionTitlePush',
        Inbox: 'actionTitleInbox',
        Webhook: 'actionTitleWebhook',
        Delay: 'actionTitleDelayBy',
        Decision: 'actionTitleDecision',
        MultiDecision: 'actionTitleMultidecision',
        Split: 'actionTitleSplit',
        AdRetarget: 'actionTitleRetarget',
        Promotion: 'actionTitlePromotion',
        End: 'actionTitleEnd',
        Event: 'actionTitleEvent'
      }
    }
  },

  computed: {
    isReadOnly() {
      return false
    }
  },

  created() {
    this.projectId = this.$options.projectId
    this.workflowId = this.$options.workflowId

    this.jobQueue = new JobQueue((saving) => (this.status.saving = saving))
    this.undoManager = new UndoManager()
    this.undoManager.setCallback(() => {
      this.history.hasUndo = this.undoManager.hasUndo()
      this.history.hasRedo = this.undoManager.hasRedo()
    })

    MxaApi.get(`projects/${this.projectId}/workflows/${this.workflowId}`)
      .then(({ data }) => {
        data.actions.forEach((action) => (action.stats = {}))
        this.$set(this, 'workflow', data)

        this.initPusherPresence()
        this.initPusher()
        this.refreshStatus()
        this.refreshStats()
      })
      .catch((error) => {
        if (error.status === 404) {
          router.replace('/not-found')
        }
      })
  },

  destroyed() {
    if (this.refreshStatsTimeout) clearTimeout(this.refreshStatsTimeout)

    this.channel.unsubscribe()
    this.presenceChannel.unsubscribe()
  },

  methods: {
    initPusher() {
      this.channel = pusher.subscribe(
        `private-project.${this.projectId}.workflow.${this.workflowId}`
      )

      this.channel.bind('workflow.update', ({ data }) => {
        Object.assign(this.workflow, data)
      })

      this.channel.bind('workflow.update.status', ({ data }) => {
        Object.assign(this.workflow, data)
      })

      this.channel.bind('workflow.validate', (data) => {
        Object.assign(this.status, data)
      })

      this.channel.bind('workflow.action.create', ({ data, parents }) => {
        if (this.getAction(data.id)) {
          return
        }

        data.stats = {}
        this.workflow.actions.push(data)

        parents.forEach(({ id, successorName }) => {
          this.getAction(id).successors[successorName] = data.id
        })
      })

      this.channel.bind(
        'workflow.action.update',
        ({ actionId: actionId, data }) => {
          const action = this.getAction(actionId)

          if (action) {
            Object.assign(action, data)
          }
        }
      )

      this.channel.bind(
        'workflow.action.delete',
        ({ actionId: actionIdId }) => {
          this.workflow.actions.some((action, index) => {
            if (action.id === actionIdId) {
              this.workflow.actions.splice(index, 1)
              return true
            }
          })
        }
      )
    },

    initPusherPresence() {
      this.presenceChannel = pusher.subscribe(
        `presence-project.${this.projectId}.workflow.${this.workflowId}`
      )

      this.presenceChannel.bind('pusher:subscription_succeeded', (members) => {
        this.members.members = Object.values(members.members)
        this.members.loading = false
      })

      this.presenceChannel.bind('pusher:member_added', (member) => {
        this.members.members.push(member.info)
      })

      this.presenceChannel.bind('pusher:member_removed', (member) => {
        this.members.members = this.members.members.filter(
          (m) => m.email !== member.id
        )
      })
    },

    updateWorkflow(data) {
      const newData = clone(data)
      const oldData = getChanges(this.workflow, newData)

      const entry = {
        undo: () => {
          Object.assign(this.workflow, oldData)
          return this.jobQueue.add((resolve) => {
            MxaApi.patch(
              `projects/${this.projectId}/workflows/${this.workflowId}`,
              oldData
            ).then(resolve)
          })
        },

        redo: () => {
          Object.assign(this.workflow, newData)
          return this.jobQueue.add((resolve) => {
            MxaApi.patch(
              `projects/${this.projectId}/workflows/${this.workflowId}`,
              newData
            ).then(resolve)
          })
        }
      }

      this.undoManager.add(entry)
      return entry.redo()
    },

    startWorkflow() {
      return MxaApi.put(
        `projects/${this.projectId}/workflows/${this.workflowId}/status`,
        {
          isOpen: true,
          isRunning: true
        }
      ).then(() => {
        this.workflow.isOpen = true
        this.workflow.isRunning = true
      })
    },

    stopWorkflow() {
      return MxaApi.put(
        `projects/${this.projectId}/workflows/${this.workflowId}/status`,
        {
          isOpen: false,
          isRunning: false
        }
      ).then(() => {
        this.workflow.isOpen = false
        this.workflow.isRunning = false
      })
    },

    pauseWorkflow() {
      return MxaApi.put(
        `projects/${this.projectId}/workflows/${this.workflowId}/status`,
        {
          isOpen: true,
          isRunning: false
        }
      ).then(() => {
        this.workflow.isOpen = true
        this.workflow.isRunning = false
      })
    },

    getActions() {
      return this.workflow.actions
    },

    getAction(actionId) {
      return this.workflow.actions.find((action) => action.id === actionId)
    },

    getActionType(action) {
      if (this.workflow.isTransactional && action.type === 'Start') {
        return 'TransactionalStart'
      }
      if (action.type === 'AdRetarget' && action.event && action.event === 1) {
        return 'AdRetargetRemove'
      }
      if (action.type === 'Promotion') {
        if (action.event && action.event === PROMOTION_ADD) {
          return 'PromotionAdd'
        }
        if (action.event && action.event === PROMOTION_REMOVE) {
          return 'PromotionRemove'
        }
        if (action.event && action.event === PROMOTION_CREDIT) {
          return 'PromotionCredit'
        }
      }

      return action.type
    },

    getActionClone(actionId) {
      return clone(this.getAction(actionId))
    },

    copyAction(actionId, pos) {
      const data = this.getActionClone(actionId)

      if (data.type === 'MultiDecision' || data.type === 'Split') {
        data.successors = Object.fromEntries(
          Object.entries(data.successors).map(([k]) => [k, ''])
        )
      } else {
        delete data.successors
      }

      delete data.id

      data.name = data.name + ' ' + i18n.t('copy')
      data.pos = pos

      return this.createAction([], data)
    },

    createAction(parents, data) {
      parents = clone(parents)

      data = Object.assign(
        {
          name: i18n.t(this.actionTypeMap[data.type]) + ' - ' + i18n.t('untitledLabel')
        },
        data,
        {
          id: shortId.generate(),
          stats: {}
        }
      )

      if (!ActionDefaults.hasOwnProperty(data.type)) {
        throw `Oops unexpected action type '${data.type}'`
      }
      data = Object.assign({}, ActionDefaults[data.type], data)

      const entry = {
        undo: () => {
          const action = this.getAction(data.id)
          if (!action) {
            return
          }

          parents.forEach(({ id, successorName }) => {
            const parentAction = this.getAction(id)
            if (parentAction) {
              parentAction.successors[successorName] = ''
            }
          })

          const index = this.workflow.actions.indexOf(action)
          this.workflow.actions.splice(index, 1)

          return this.jobQueue.add((resolve) => {
            MxaApi.delete(
              `projects/${this.projectId}/workflows/${this.workflowId}/actions/${data.id}`
            ).then(resolve)
          })
        },

        redo: () => {
          const dataObj = clone(data)
          this.workflow.actions.push(dataObj)

          parents.forEach(({ id, successorName }) => {
            const parentAction = this.getAction(id)
            if (parentAction) {
              parentAction.successors[successorName] = data.id
            }
          })

          return this.jobQueue.add((resolve) => {
            MxaApi.post(
              `projects/${this.projectId}/workflows/${this.workflowId}/actions`,
              { data, parents }
            ).then(({ data }) => {
              dataObj.action_id = data.action_id
              resolve()
            })
          })
        }
      }

      this.undoManager.add(entry)
      return entry.redo()
    },

    updateAction(actionId, data) {
      const newData = clone(data)
      const oldData = getChanges(this.getAction(actionId), newData)

      const entry = {
        undo: () => {
          const action = this.getAction(actionId)
          if (!action) {
            return
          }

          Object.assign(action, oldData)
          return this.jobQueue.add((resolve) => {
            MxaApi.patch(
              `projects/${this.projectId}/workflows/${this.workflowId}/actions/${actionId}`,
              oldData
            ).then(resolve)
          })
        },

        redo: () => {
          const action = this.getAction(actionId)
          if (!action) {
            return
          }

          Object.assign(action, newData)
          return this.jobQueue.add((resolve) => {
            MxaApi.patch(
              `projects/${this.projectId}/workflows/${this.workflowId}/actions/${actionId}`,
              newData
            ).then(resolve)
          })
        }
      }

      this.undoManager.add(entry)
      return entry.redo()
    },

    deleteAction(actionId) {
      const oldData = {
        parents: [],
        data: this.getActionClone(actionId)
      }

      this.workflow.actions.forEach((action) => {
        Object.keys(action.successors).forEach(function (successorName) {
          if (action.successors[successorName] === actionId) {
            oldData.parents.push({
              id: action.id,
              successorName
            })
          }
        })
      })

      const entry = {
        undo: () => {
          this.workflow.actions.push(clone(oldData.data))
          oldData.parents.forEach(({ id, successorName }) => {
            const parentAction = this.getAction(id)
            if (parentAction) {
              parentAction.successors[successorName] = actionId
            }
          })

          return this.jobQueue.add((resolve) => {
            MxaApi.post(
              `projects/${this.projectId}/workflows/${this.workflowId}/actions`,
              oldData
            ).then(resolve)
          })
        },

        redo: () => {
          const action = this.getAction(actionId)
          if (!action) {
            return
          }

          oldData.parents.forEach(({ id, successorName }) => {
            const parentAction = this.getAction(id)
            if (parentAction) {
              parentAction.successors[successorName] = ''
            }
          })
          const index = this.workflow.actions.indexOf(action)
          this.workflow.actions.splice(index, 1)

          return this.jobQueue.add((resolve) => {
            MxaApi.delete(
              `projects/${this.projectId}/workflows/${this.workflowId}/actions/${actionId}`
            ).then(resolve)
          })
        }
      }

      this.undoManager.add(entry)
      return entry.redo()
    },

    refreshStatus() {
      MxaApi.get(
        `projects/${this.projectId}/workflows/${this.workflowId}/status`
      ).then(({ data }) => {
        this.status.isValid = data.isValid
        this.status.messages = data.messages
      })
    },

    refreshStats() {
      if (this.refreshStatsTimeout) clearTimeout(this.refreshStatsTimeout)

      MxaApi.get(
        `projects/${this.projectId}/workflows/${this.workflowId}/stats`
      )
        .then(({ data }) => {
          Object.keys(data).forEach((actionIdId) => {
            const action = this.getAction(actionIdId)
            if (action) {
              action.stats = data[actionIdId]
            }
          })
        })
        .finally(
          () =>
            (this.refreshStatsTimeout = setTimeout(
              this.refreshStats,
              10 * 1000
            ))
        )
    },

    undo() {
      return this.undoManager.undo()
    },

    redo() {
      return this.undoManager.redo()
    }
  }
})
